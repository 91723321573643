import { useEffect, useState } from 'react';
import {
    Bold12Text,
    Bold13Text,
    Bold7Text,
    Medium10Text,
    Medium12Text,
    Medium8Text,
    Regular10Text,
    SemiBold12Text,
    SemiBold13Text,
    SnapBadge,
    SnapCard
} from '@snapmint/ui-components';
import { useFinalPlan } from '../../page/common/plan.hook';
import { useUrlParamParser } from '../../page/util/url-param-parse.hook';
import { SnapShimmer } from '../shimmer/shimmer.component';
import { BASE_PATH } from '../../enum/base.enum';
import { Plan } from '../../response/plan.res';
import { isPayLater } from '../../utils/pay-later.helper';
import { CashbackRedeem } from './cashback-detail.component'
import { useApp } from '../../app.hook'

export const OrderDetailAfterPlanComponentV2 = (props: any) => {
    const [_plan, setPlan] = useState<any>();
    const [orderVal, setOrderVal] = useState<Number>(0);
    const {
        cashbackConfig: { cashBackDetailsLoaded },
        divideCashBack,
        isCashBackUsed
    } = useApp();

    const transactionParamModel = useUrlParamParser();
    const { plan } = useFinalPlan();
    const { merchantName, orderValue } = transactionParamModel;
    const checkIsPayLater = isPayLater();
    const emiOrPayLaterText = checkIsPayLater ? 'Pay Later' : 'EMI';
    const snapBadgeText = checkIsPayLater ? '0 Extra Cost' : '0% EMI';
    const snapAppText = checkIsPayLater ? 'Pay Later on Snapmint App' : 'Pay EMIs on Snapmint App';

    useEffect(() => {
        if (props.plan) {
            const __plan = props.plan;
            if (!__plan.tdp) {
                __plan.tdp = __plan.dp;
            }

            if (!__plan.tdp && __plan.dp) {
                __plan.tdp = __plan.dp;
            }
            if (!__plan.emiAmount) {
                __plan.emiAmount = __plan.emi;
            }
            if (!__plan.emiStartDate && __plan.startAt) {
                __plan.emiStartDate = __plan.startAt;
                const endDate = new Date(__plan.startAt);
                endDate.setMonth(endDate.getMonth() + (__plan.tenure - 1));
                __plan.emiEndDate = endDate;
            }

            setPlan(__plan);
        } else if (plan) {
            setPlan(plan as any);
        }
    }, [plan, props.plan]);

    useEffect(() => {
        try {
            const _orderValue = Number(orderValue);
            setOrderVal(_orderValue);
        } catch (err) {
            console.log(err);
        }
    }, [orderValue]);

    const { finalDp, finalEmi, totalCashBack } = divideCashBack(
        _plan?.tdp,
        _plan?.emiAmount,
        _plan?.tenure
    );

    return (
        <SnapCard className="flex flex-col bg-white items-center px-[12px] py-0 mx-0 w-[100%] font-interMedium mb-[16px]">
            {_plan && _plan.planId && `${_plan.planId}`.length > 0 && cashBackDetailsLoaded ? (
                <>
                    <div className="flex items-center gap-[4px] width-full bg-[#F2F2F2] px-[8px] py-2 rounded-b-lg">
                        <>
                            {merchantName && merchantName.length > 0 && (
                                <>
                                    <Medium8Text
                                        text={`${merchantName}`}
                                        className="text-lightGrey truncate !font-robotoMedium !text-[#3d494b]"
                                    />
                                    <div className="h-4 w-[1px] bg-lineBreak " />
                                </>
                            )}
                            <p className="truncate !font-robotoBold !text-[#3d494b] text-[8px]">
                                Order Total :{' '}
                                <span
                                    className={`font-robotoMedium text-[#657173] ${
                                        totalCashBack > 0 && 'line-through'
                                    }`}
                                >
                                    ₹{orderVal.toFixed(0)}
                                </span>
                                {totalCashBack > 0 && (
                                    <span className="ml-1">₹{Number(orderVal.toFixed(0)) - totalCashBack}</span>
                                )}
                            </p>
                            {totalCashBack > 0 && (
                                <div className="bg-[#EAF9C7] text-[8px] font-robotoBold px-[6px] rounded-full text-[#3D494B]">
                                    Cashback : ₹{totalCashBack} saved
                                </div>
                            )}
                        </>
                    </div>
                    <div
                        className={`flex items-start justify-between gap-6 sm:w-full sm:gap-6 xs:gap-4 ${
                            _plan && _plan.tenure == 1 ? 'pt-[8.5px]' : 'pt-[8.5px]'
                        }`}
                    >
                        <>
                            <div className='flex items-center gap-6'>
                                <div className="flex flex-col items-center min-w-fit">
                                    <Bold13Text text="Pay Now" className="text-[#657173] mb-1 !font-robotoBold" />

                                    <Bold12Text
                                        text={`₹ ${finalDp?.toFixed(0)}`}
                                        className="text-[#3D494B] mb-1 !font-robotoBold"
                                    />

                                    <Medium10Text
                                        text="Downpayment"
                                        className={`!text-[10px] !font-robotoMedium tracking-[.2px] text-[#657173] mb-1 ${
                                            checkIsPayLater ? 'invisible' : ''
                                        }`}
                                    />
                                </div>
                                <img
                                    src={`${BASE_PATH}/assets/images/plus_sign.svg`}
                                    className="w-[25.45px] h-[24px]  mb-[12px]"
                                    alt=""
                                />
                            </div>
                            <div className="flex items-center gap-6">
                                <div className="flex flex-col items-center min-w-[64px]">
                                    <Bold13Text
                                        text={emiOrPayLaterText}
                                        className="text-[#657173] mb-1 !text-[13px] !font-robotoBold"
                                    />

                                    <Bold12Text
                                        text={`₹ ${finalEmi?.toFixed(0)}`}
                                        className="text-[#3D494B] mb-1 !text-[12px] ! !font-robotoBold"
                                    />

                                    <Medium10Text
                                        text={`x${_plan.tenure} months`}
                                        className={`text-[#657173] !font-robotoMedium !text-[10px]  mb-1 ${
                                            checkIsPayLater ? 'invisible' : ''
                                        } `}
                                    />
                                </div>
                                <img
                                    src={`${BASE_PATH}/assets/images/arrow_right.svg`}
                                    className="w-[25.45px] h-[24px] mb-[12px]"
                                    alt=""
                                />
                            </div>
                            <>
                                <div className="flex flex-col items-center min-w-[64px]">
                                    <Bold13Text text="Total" className="text-[#657173] mb-1 !font-robotoBold" />
                                    <Bold12Text
                                        text={`₹ ${_plan.totalYouPay.toFixed(0)}`}
                                        className={`text-[#3D494B] mb-1 !font-robotoBold`}
                                    />

                                    <SnapBadge
                                        className={`rounded-full flex items-center justify-center !bg-[#AFE137] h-[16px] bg-emiTab mb-[6px] ${
                                            _plan && _plan.isZeroPercent ? '' : 'hidden'
                                        }`}
                                    >
                                        <Bold7Text
                                            text={snapBadgeText}
                                            className="text-[9px] !text-[#014751] !font-robotoBold"
                                        ></Bold7Text>
                                    </SnapBadge>
                                </div>
                            </>
                        </>
                    </div>
                    <CashbackRedeem />
                    <div className="flex items-center text-tiny justify-between mt-[4px] mb-[6px] gap-2">
                        <img
                            src={`${BASE_PATH}/assets/images/snapmint_green_small_logo.svg`}
                            alt=""
                            className="w-[18px] h-[16.76px]"
                        />
                        <Medium12Text
                            text={snapAppText}
                            className="!text-[10px] text-[#90A4A7] leading-[13px] !font-robotoMedium"
                        />
                        <span className="bg-[#90A4A7] w-2 h-2 rounded-lg" />

                        {_plan && (
                            <div className="!font-robotoMedium !text-[#90A4A7] text-[10px] leading-[13px]">
                                {new Date(_plan.emiStartDate).toLocaleDateString('en-US', { day: 'numeric' })}
                                <sup>rd </sup>
                                {new Date(_plan.emiStartDate).toLocaleDateString('en-US', {
                                    month: 'short',
                                    year: '2-digit'
                                })}{' '}
                                {!checkIsPayLater ? (
                                    <>
                                        -{' '}
                                        {new Date(_plan.emiEndDate).toLocaleDateString('en-US', {
                                            month: 'short',
                                            year: '2-digit'
                                        })}
                                    </>
                                ) : null}
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <SnapShimmer className="w-[100%] h-[132px] mt-[0px]" />
            )}
        </SnapCard>
    );
};
